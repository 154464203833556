<template>
  <div class="flix-panel-heading flix-text-center">
    <!-- <flixIcon :id="$getStatusIcon(entry)" class="flix-form-group" style="font-size: 2em;"/> -->
    <h6 class="flix-html-h6">
      {{ $store.getters.assistentsByID[entry.form].title }}
    </h6>
    <div class="flix-html-small" style="display: flex; align-items: center; justify-content: center; gap: 5px">
      <flix-icon id="calendar"></flix-icon>
      <bookingflix-calendar-items
        style="font-weight: bold"
        :settings="getJsonDate(entry)"
      ></bookingflix-calendar-items>
    </div>
    <capacity :entry="entry" />
  </div>
</template>

<script>
export default {
  props: { entry: Object },
  components: {
    capacity() {
      return import('@/components/booking/capacity')
    }
  },
  methods: {
    getJsonDate(client) {
      return JSON.stringify({
        lang: this.$i18n.locale,
        view: 'date-string',
        date: [client.begin, client.end]
      })
    }
  }
}
</script>
